import type { JobType } from '~~/types/job-type'

export const useJobTypes = () => {
    const currentJobType = useState<JobType | null>('current-job-type', () => null)

    const setDefaultJobType = (jobTypes: JobType[]) => {
        return jobTypes[0]
    }

    const isReceivementSelectionModalVisible = useState('is-receivement-selection-modal-visible', () => false)
    const isReceivementToStockSelectionModalVisible = useState('is-receivement-to-stock-selection-modal-visible', () => false)

    const listJobTypes: () => JobType[] = () => {
        const jobTypes = [
            {
                name: 'stockCount',
                reference: 'destination',
            },
            {
                name: 'receivement',
                reference: 'destination',
            },
            {
                name: 'receivementToStock',
                reference: 'source',
            },
        ]
        if (!currentJobType.value) { currentJobType.value = setDefaultJobType(jobTypes) }
        return jobTypes
    }

    watchEffect(() => {
        listJobTypes()
    })

    return {
        currentJobType,
        listJobTypes,
        isReceivementSelectionModalVisible,
        isReceivementToStockSelectionModalVisible,
    }
}

export const useReceivementType = () => {
    const currentReceivementType = useState<string>('current-receivement-type', () => '')

    const receivementTypesList = {
        devolution: { label: 'Devolução', value: 'devolution', metadata: 'Devolução' },
        waste_faas: { label: 'DevoluçãoDesperdicioFAAS', value: 'waste_faas', metadata: 'DevoluçãoDeperdicioFAAS' },
    }

    return {
        currentReceivementType,
        receivementTypesList,
    }
}

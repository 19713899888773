import { client } from '../shared'
import { useWarehouse } from '..'
import type { StockEntry } from './types'
import { useJobTypes } from '~~/composables/job-type'
import type { Syncable } from '~~/composables/offline'

export type { StockEntry } from './types'

const { warning } = useNotification()

export const useStock = () => {
    const { currentWarehouse } = useWarehouse()
    const { ifOnline } = useOffline()

    const { searchTerm, fuzzyMatch, sortBy } = useSearch()
    const { currentJobType } = useJobTypes()

    const stockEntryList = useState<StockEntry[]>('stock-entry-list', () => [])
    const receivementEntryList = useState<StockEntry[]>('receivement-entry-list', () => [])

    const stockEntryListDisplaySize = useState<number>('stock-entry-list-display-size', () => 20)

    const mapping = () => {
        if (currentJobType.value?.name === 'receivement') {
            return receivementEntryList.value.map((i) => {
                return { source: null, destination: i }
            })
        }
        if (currentJobType.value?.name === 'stockCount') {
            return stockEntryList.value.map((i) => {
                return { source: null, destination: i }
            })
        }
        if (currentJobType.value?.name === 'receivementToStock') {
            return receivementEntryList.value.map((i) => {
                const stockEntry = stockEntryList.value.find((j) => {
                    return j.key.id_raw_product === i.key.id_raw_product
                        && i.key?.id_maturation_type === j.key?.id_maturation_type
                        && i.key?.id_quality_type === j.key?.id_quality_type
                })
                return { source: i, destination: stockEntry }
            }).filter(i => i.source.quantity > 0)
        }
    }

    const getAllEntries = () => [...stockEntryList.value, ...receivementEntryList.value]

    const fetchStockEntryList = async () => {
        const stockEntryType = 'STOCK' // idMapping['from]
        const receivementEntryType = 'RECEIVEMENT' // idMapping['to]

        const { data: stockEntries } = await client('/stock/entries/warehouse/{id_warehouse}', 'get')({
            params: { position: stockEntryType },
            path: { id_warehouse: currentWarehouse.value.id_warehouse },
        })

        const { data: receivementEntries } = await client('/stock/entries/warehouse/{id_warehouse}', 'get')({
            params: { position: receivementEntryType },
            path: { id_warehouse: currentWarehouse.value.id_warehouse },
        })

        stockEntryList.value = stockEntries
        receivementEntryList.value = receivementEntries
        return stockEntryList.length
    }

    const filteredStockEntryList = computed(() => {
        const search = searchTerm.value.toLowerCase()

        return (mapping() || [])
            .filter(({ source, destination }) => {
                const usedEntry = currentJobType.value?.reference === 'source' ? source : destination
                const { name, qualifiers } = usedEntry
                return fuzzyMatch([
                    name,
                    ...Object.values(qualifiers),
                ].join(' '), search)
            })
            .sort((a, b) => {
                const referenceCard = currentJobType.value?.reference || 'destination'
                const usedA = a[referenceCard]
                const usedB = b[referenceCard]
                return ({
                    '': 0,
                    'asc': usedA?.name.localeCompare(usedB?.name),
                    'desc': usedB?.name.localeCompare(usedA?.name),
                })[sortBy.value] || 0
            })
            .slice(0, stockEntryListDisplaySize.value)
    })

    const updateStockEntryItem = (id: string, item: Partial<Syncable<StockEntry>>) => {
        stockEntryList.value = stockEntryList.value.map((stockEntry) => {
            if (stockEntry.id_stock_entry === id) {
                return { ...stockEntry, ...item }
            }
            return stockEntry
        })
    }

    const updateRecievementEntryItem = (id: string, item: Partial<Syncable<StockEntry>>) => {
        receivementEntryList.value = receivementEntryList.value.map((stockEntry) => {
            if (stockEntry.id_stock_entry === id) {
                return { ...stockEntry, ...item }
            }
            return stockEntry
        })
    }

    const updateStockPosition = async (id: string, quantity: number) => {
        const { isSynced } = await ifOnline(async () => {
            updateStockEntryItem(id, { isSyncing: true })
            response = await client('/stock/entries/{id_stock_entry}', 'patch')({
                path: {
                    id_stock_entry: id,
                },
                body: {
                    quantity,
                },
            })
            updateStockEntryItem(id, { isSyncing: false, isNotSynced: false })
        })

        updateStockEntryItem(id, { quantity, isNotSynced: !isSynced })
    }

    const createStockEntryMovementRecord = async (
        source_id: string, destination_id: string | null, quantity: number, metadata: object,
    ) => {
        const referenceType = currentJobType.value?.reference
        const referenceId = currentJobType.value?.reference === 'source' ? source_id : destination_id
        const reference = mapping()?.map((elem) => {
            if (referenceType === 'source') {
                return elem.source
            }

            return elem.destination
        }).filter(elem => elem?.id_stock_entry === referenceId)[0]

        const updatedQuantity = referenceType === 'source'
            ? reference?.quantity - quantity
            : reference?.quantity + quantity

        const { isSynced } = await ifOnline(async () => {
            updateStockEntryItem(referenceId, { isSyncing: true })
            await client('/stock/entries/movement', 'post')({
                body: {
                    id_stock_entry_source: source_id,
                    id_stock_entry_destination: destination_id,
                    quantity,
                    data: metadata,
                },
            })
            const status = response?.status
            if (status >= 400) {
                warning('Ocorreu um erro!', `O produto ${reference.name} não foi atualizado.`)
            }

            updateRecievementEntryItem(referenceId, { quantity: updatedQuantity, isSyncing: false, isNotSynced: false })
        })
        updateRecievementEntryItem(referenceId, { quantity: updatedQuantity, isNotSynced: !isSynced })
    }

    const clearStock = async () => {
        await client('/stock/entries/warehouse/{id_warehouse}/clear', 'post')({
            path: {
                id_warehouse: currentWarehouse.value.id_warehouse,
            },
        })
        await fetchStockEntryList()
    }

    const syncStock = async () => {
        await client('/stock/entries/warehouse/{id_warehouse}/sync', 'post')({
            path: {
                id_warehouse: currentWarehouse.value.id_warehouse,
            },
        })
        await fetchStockEntryList()
    }

    watch(() => searchTerm, () => {
        stockEntryListDisplaySize.value = 20
    })

    return {
        getAllEntries,
        fetchStockEntryList,
        createStockEntryMovementRecord,
        updateStockPosition,
        clearStock,
        syncStock,
        filteredStockEntryList,
        stockEntryList,
        stockEntryListDisplaySize,
    }
}

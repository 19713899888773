import { client } from '../shared'
import type { User } from './types'

export const useUser = () => {
    const user = useSupabaseUser()
    const userInfo = useState<User | null>('user-info', () => null)

    async function getUserByIdAuth() {
        if (user.value && userInfo.value === null) {
            const { data } = await client('/user/', 'get')({
                params: {
                    id_auth: user.value.id,
                },
            })

            const fetchedUserInfo = data.pop()
            if (!fetchedUserInfo) {
                return
            }

            userInfo.value = fetchedUserInfo
            if (__DEV__) {
                console.log('[useUser] Setting user info', fetchedUserInfo.email)
            }
        }
    }

    return {
        getUserByIdAuth,
        userInfo,
    }
}

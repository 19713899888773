<script lang="ts" setup>
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    TransitionRoot,
} from '@headlessui/vue'
import { useMotions } from '@vueuse/motion'
import { useDebounceFn } from '@vueuse/core'
import type { SelectItem } from '~~/types/select'

const props = defineProps<{
    label?: string
    modelValue?: any
    disabled?: boolean
    type?: string
    items: SelectItem[]
    placeholder?: string
    disableInput?: boolean
    error?: string | false
    onQuery?: (query: string, items: SelectItem[]) => Promise<SelectItem[]>
    onAdd?: (query: string) => any
}>()
const emit = defineEmits(['update:modelValue', 'add', 'selected', 'clear'])
const { fuzzyMatch } = useSearch()

const isFocused = ref(false)
const internalValue = ref(props.modelValue)
const searchQuery = ref('')
const inputElement = ref()
const currentItems = ref([])
const isLoading = ref(false)
const hasSelectedItem = ref(false)
const selectedItemImage = ref('')

const onFocus = () => {
    isFocused.value = true
    hasSelectedItem.value = false
}
const onBlur = () => isFocused.value = false

const updateItems = useDebounceFn(async (callback: (query: string, items: SelectItem[]) => Promise<SelectItem[]>) => {
    isLoading.value = true
    const newItems = await callback(searchQuery.value, props.items)
    isLoading.value = false
    const filteredNewItems = newItems.filter(item =>
        'label' in item
            && 'value' in item,
    )
    currentItems.value = searchQuery.value ? filteredNewItems : props.items
}, 300)

const onToggle = async () => {
    isFocused.value = !isFocused.value
    hasSelectedItem.value = false
}

const onInput = async (event: { target: { value: string } }) => {
    searchQuery.value = event.target.value

    if (props.onQuery) {
        await updateItems(props.onQuery)
    }

    hasSelectedItem.value = false
}

const onClear = () => {
    emit('update:modelValue', null)
    emit('clear')
    searchQuery.value = ''
    currentItems.value = props.items
    selectedItemImage.value = ''
}

const filteredItems = computed(() =>
    currentItems.value.filter(item => Boolean(item) && fuzzyMatch(item.label, searchQuery.value)),
)

const showAddOption = computed(() => {
    if (props.onAdd && searchQuery.value) {
        return !filteredItems.value.some(item => item.label.toLowerCase().trim() === searchQuery.value.toLowerCase().trim())
    }
    return false
})

watchEffect(() => {
    if (!searchQuery.value) {
        currentItems.value = props.items
    }
})

watchEffect(() => {
    if (props.items) {
        currentItems.value = props.items
    }
})

watch([() => props.modelValue], ([newValue]) => {
    if (!newValue) { return }

    const item = props.items.find(item => item.value === newValue)
    if (!item) { return }

    internalValue.value = item
})

watch(internalValue, (newValue, oldValue) => {
    if (oldValue && oldValue.value && newValue.value !== oldValue.value) {
        emit('update:modelValue', newValue.value)
        selectedItemImage.value = newValue.image || ''
        emit('selected', newValue)
    }
    else if (!oldValue) {
        emit('update:modelValue', newValue.value)
        selectedItemImage.value = newValue.image || ''
        emit('selected', newValue)
    }
    isFocused.value = false
    inputElement.value.el.blur()
})

onMounted(() => {
    if (!props.modelValue) { return }
    const item = props.items.find(item => item.value === props.modelValue)

    if (!item) { return }
    internalValue.value = item
})
</script>

<template>
    <Combobox v-slot="{ open }" v-model="internalValue">
        <div relative mt-1>
            <div
                flex b-1 b-primary-100 rounded-2 relative mt-2 bg-white
                cursor-pointer items-center
                transition
                :class="[
                    disabled ? '!cursor-not-allowed !b-textcolor-50' : '',
                    isFocused ? '!b-primary-300' : '',
                    error ? '!b-red' : '',
                ]"
            >
                <nuxt-img v-if="selectedItemImage" :src="selectedItemImage" h-5 w-5 rounded-2 mr-1 relative top-6px left-3 />
                <ComboboxInput
                    ref="inputElement"
                    :class="[
                        disabled ? 'text-textcolor-300 cursor-pointer' : '',
                    ]" rounded-2 w-full outline-none
                    px-3 pb-2 pt-5 text-textcolor-300
                    bg-transparent
                    relative
                    z-2
                    type="search"
                    :disabled="disableInput"
                    :display-value="(item) => item.label || ''"
                    :placeholder="placeholder"
                    @change="onInput($event)"
                    @focus="onFocus"
                    @blur="onBlur"
                />
                <div
                    v-if="label"
                    class="peer-focus:top-6px peer-focus:text-13px"
                    :class="[
                        disabled ? 'text-textcolor-300' : '',
                        modelValue !== undefined || Boolean(placeholder) ? '!top-6px !text-13px' : '',
                    ]"
                    absolute text-black top-14px left-3
                    text-primary-300
                    z-0
                    font-bold transition-all
                >
                    {{ label }}
                </div>
                <div
                    v-if="searchQuery" p-2px rounded-full bg-gray-100
                    transition mr-3
                    @click="onClear"
                >
                    <div text-gray i-material-symbols-close />
                </div>
                <ComboboxButton>
                    <div
                        p-2px rounded-full bg-primary-100 transition
                        mr-3
                        @click="onToggle"
                    >
                        <div v-if="isLoading" text-secondary i-eos-icons-loading />
                        <div v-else text-primary-300 i-material-symbols-arrow-drop-down />
                    </div>
                </ComboboxButton>
            </div>
            <TransitionRoot
                leave="transition ease-in duration-100"
                leave-from="opacity-100"
                leave-to="opacity-0"
                :show="open || isFocused"
                @after-leave="searchQuery = ''"
            >
                <ComboboxOptions
                    :static="true"
                    class="absolute z-99 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <div
                        v-if="filteredItems.length === 0 && searchQuery !== ''"
                        flex items-center gap-1 px-2 text-textcolor-300 text-3 py-2
                    >
                        <div i-carbon-non-certified text-4 />
                        Nenhum item encontrado.
                    </div>
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.label + item.value"
                        v-slot="{ selected, active }"
                        as="template"
                        :value="item"
                        relative z-9
                    >
                        <cc-select-item
                            :image="item.image"
                            :selected="selected"
                            :active="active"
                        >
                            {{ item.label }}
                        </cc-select-item>
                    </ComboboxOption>
                    <div
                        v-if="showAddOption"
                        cursor-pointer
                        flex items-center
                        p-1 rounded mx-1
                        hover:bg-backgroundlight
                        text-textcolor-700 font-600 transition
                        @click="() => onAdd(searchQuery)"
                    >
                        <div i-carbon-add mr-1 />
                        Adicionar <span ml-6px class="bg-primary-100/50" px-1 rounded>{{ searchQuery }}</span>
                    </div>
                </ComboboxOptions>
            </TransitionRoot>
            <div v-if="error" text-red text-12px>
                {{ error }}
            </div>
        </div>
    </Combobox>
</template>

<style scoped>
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
</style>

<script lang="ts" setup>
const props = defineProps<{
    message: string
    description?: string
    warning?: boolean
}>()

const iconUrl = computed(() => `/icons/notification-${props.warning ? 'warning' : 'success'}.svg`)
</script>

<template>
    <div
        z-9999
        b-2 b-primary-100 max-w-300px
        bg-white flex items-center
        py-1 rounded-xl px-3 mx-auto
        shadow-md shadow="primary-700/20"
        font-sans mb-2
        :class="[
            props.warning ? '!bg-white/90 !b-secondary-100' : '',
            props.description ? '!justify-start' : '!justify-center',
        ]"
    >
        <nuxt-img :src="iconUrl" w-40px relative top-2px mr-2/>
        <div text-center :class="[{ [`!text-left`]: description }]">
            <div
                text-primary font-700 text-13px
                class="notification-title" :class="[{
                    [`
                    !text-secondary
                `]: props.warning,
                }]"
            >
                {{ message }}
            </div>
            <div
                v-if="description" text-12px
                text-textcolor-500
            >
                {{ description }}
            </div>
        </div>
    </div>
</template>

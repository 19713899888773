import { client } from '../shared'
import { useUser } from '../user'
import type { Warehouse } from './types'
export type { Warehouse } from './types'

export const useWarehouse = () => {
    const user = useSupabaseUser()
    const { userInfo } = useUser()
    const currentWarehouse = useState<Warehouse | null>('current-warehouse', () => null)

    const fetchInitialWarehouse = async () => {
        // Check if user has a warehouse setted
        if (user.value?.id && userInfo?.value?.warehouse?.id_warehouse) {
            currentWarehouse.value = userInfo.value.warehouse
        }

        if (user.value?.id && currentWarehouse.value === null) {
            const { data: warehouses } = await client('/warehouse/', 'get')({
                params: { size: 1000 },
            })
            currentWarehouse.value = warehouses.items.filter(warehouse =>
                !userInfo.value?.location?.id_location
                || warehouse?.id_location === userInfo.value?.location?.id_location)[0]
        }
    }

    const listWarehouses = async () => {
        const { data: warehouses } = await client('/warehouse/', 'get')({
            params: { size: 1000 },
        })
        const userLocationId = userInfo.value?.location?.id_location
        console.log('userLocationId', userLocationId)
        return warehouses.items
    }

    watchEffect(fetchInitialWarehouse)

    return {
        currentWarehouse,
        fetchInitialWarehouse,
        listWarehouses,
    }
}

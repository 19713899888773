import { client } from '../shared'
import { useUser } from '../user'
import type { ContactCompany } from './types'

export const useContactCompany = () => {
    const currentContactCompany = useState<ContactCompany | null>('current-contact-company', () => null)
    const contactCompanies = useState<ContactCompany[] | null>('contact_companies', () => null)

    const { userInfo } = useUser()

    const fetchContactCompanies = async ({ page = 1, size = 20, search }: { page?: number; size?: number; search?: string } = {}) => {
        console.log('Listing Contact Companies')

        const { data: companies } = await client('/crm/contact_company/', 'get')({
            params: { page, size, q: search, id_location: userInfo.value?.id_location },
        })
        contactCompanies.value = companies.items.sort((a: ContactCompany, b: ContactCompany) => (a.name > b.name ? 1 : -1)) || []
    }

    return {
        contactCompanies,
        currentContactCompany,
        fetchContactCompanies,
    }
}

import type { StockEntry } from './api/brain/stock'

const { warning } = useNotification()

export const useExpandedCardManager = () => {
    const expandedCards = useState<string[]>('expanded-cards-list', () => [])

    const checkExpandedCards = (entryLists: StockEntry[]) => {
        if (expandedCards.value.length > 0) {
            const firstOpenEntry = entryLists.find(
                e => e.id_stock_entry === expandedCards.value[0],
            )
            warning(`Já existe uma operação em andamento!\nVerifique o item ${firstOpenEntry?.name}`)
        }
    }

    const addToExpanded = (entry: StockEntry, entryLists: StockEntry[]) => {
        checkExpandedCards(entryLists)
        expandedCards.value.push(entry.id_stock_entry)
    }

    const removeFromExpanded = (entry: StockEntry) => {
        expandedCards.value = expandedCards.value.filter(e => e !== entry.id_stock_entry)
    }

    const resetExpandedCards = () => {
        expandedCards.value = []
    }

    return {
        addToExpanded,
        checkExpandedCards,
        expandedCards,
        resetExpandedCards,
        removeFromExpanded,
    }
}

import axios from 'axios'
import type { paths } from '../definition'

type APIPaths = keyof paths
type PathMethods<T extends APIPaths> = keyof paths[T]
type PathResponses<T extends APIPaths, U extends PathMethods<T>> = paths[T][U] extends { responses: infer R } ? R : null | undefined
type InferSuccessfulResponse<T extends APIPaths, U extends PathMethods<T>> = PathResponses<T, U> extends { 200: infer R } ? R : any
type ContentFromResponse<T extends { content?: { 'application/json': any } }> = T extends { content: { 'application/json': infer R } } ? R : null

type QueryParametersFromPath<T extends APIPaths, U extends PathMethods<T>> = paths[T][U] extends { parameters: infer R } ? (R extends { query: infer P } ? P : null | undefined) : null | undefined
type PathParameterFromPath<T extends APIPaths, U extends PathMethods<T>> = paths[T][U] extends { parameters: infer R } ? (R extends { path: infer P } ? P : null | undefined) : null | undefined
type RequestBodyFromPath<T extends APIPaths, U extends PathMethods<T>> = paths[T][U] extends { requestBody: infer R }
    ? (
        R extends { content: { 'application/json': infer C } } ? C : null | undefined
    )
    : null | undefined

export const client = <T extends APIPaths, U extends PathMethods<T>>(path: T, method: U) => {
    type RequestResponse = ContentFromResponse<InferSuccessfulResponse<T, U>>

    const { apiUrl, apiAuth, appVersion } = useRuntimeConfig().public
    // const token = useSupabaseToken()
    const user = useSupabaseUser()

    if (!apiUrl) {
        throw new Error('API URL is not defined')
    }

    const http = axios.create({
        baseURL: apiUrl,
        headers: {
            // 'Authorization': `Bearer ${token.value}`,
            'X-App-Name': 'estocaqui',
            'Api-Key': apiAuth,
            'X-App-Version': appVersion,
            'X-App-User': user.value?.email ?? '',
        },
    })

    interface OperationOptions {
        params?: QueryParametersFromPath<T, U>
        path?: PathParameterFromPath<T, U>
        body?: RequestBodyFromPath<T, U>
    }

    return (
        options: OperationOptions,
    ) => {
        let transformedPath = path as string
        if (options.path !== undefined) {
            transformedPath = path.replace(/{([^}]+)}/g, (_, p) => (options.path as any)[p])
        }

        if (method === 'get') {
            return http.get<RequestResponse>(`${apiUrl}${transformedPath}`, { params: options.params })
        }
        else if (method === 'post') {
            return http.post<RequestResponse>(`${apiUrl}${transformedPath}`, options.body, { params: options.params })
        }
        else if (method === 'put') {
            return http.put<RequestResponse>(`${apiUrl}${transformedPath}`, options.body, { params: options.params })
        }
        else if (method === 'delete') {
            return http.delete<RequestResponse>(`${apiUrl}${transformedPath}`, { params: options.params })
        }
        else if (method === 'patch') {
            return http.patch<RequestResponse>(`${apiUrl}${transformedPath}`, options.body, { params: options.params })
        }
    }
}


export type Syncable<T> = {
    [K in keyof T]: T[K]
} & {
    isNotSynced?: boolean
    isSyncing?: boolean
}

export type SyncableDict<T> = {
    [key: string]: Syncable<T>;
  };


export const useOffline = () => {
    const {
        isOnline,
        effectiveType,
    } = useNetwork()

    const promiseList = useState<(() => Promise<any>)[]>('offline-job-list', () => [])
    const isSynchronizing = useState<boolean>('offline-is-synchronizing', () => false)

    const synchronize = async () => {
        console.log('Synchronizing...')
        const results = await Promise.all(promiseList.value.map(cb => cb()))
        promiseList.value = []
        return results
    }

    const ifOnline = async <T>(callback: () => Promise<T>) => {
        if (isOnline.value) {
            return {
                isSynced: true,
                data: callback(),
            }
        }
        else {
            promiseList.value.push(callback)
            console.log('adding to promise list')
            return {
                isSynced: false,
                data: null,
            }
        }
    }

    const watchNetwork = () => {
        watch([isOnline], async ([connected]) => {
            if (connected) {
                if (process.client) {
                    isSynchronizing.value = true
                    await synchronize()
                    isSynchronizing.value = false
                }
            }
        })
    }

    const isOffline = computed(() => !isOnline.value || effectiveType.value === 'slow-2g' || effectiveType.value === '2g')

    return { isOffline, synchronize, ifOnline, watchNetwork, isSynchronizing }
}

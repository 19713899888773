export const useSearch = () => {
    const searchTerm = useState('search', () => '')
    const sortBy = useState('sort-by', () => 'asc')

    const fuzzyMatch = (value: string, searchTerm: string) => {
        const searchTokens = searchTerm.split(' ')
        const valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
        for (const token of searchTokens) {
            const tokenNormalized = token.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
            const regex = tokenNormalized
                ? new RegExp(
                    tokenNormalized.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
                    'i')
                : /.*/
            if (!regex.test(valueNormalized)) {
                return false
            }
        }
        return true
    }

    const toggleSortBy = () => {
        const values = ['asc', 'desc']
        const index = values.indexOf(sortBy.value)
        sortBy.value = values[(index + 1) % values.length]
    }

    return { fuzzyMatch, searchTerm, sortBy, toggleSortBy }
}
